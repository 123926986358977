import React from "react";

const Vacatsy = ({ size = 142 }) => {
  const width = size;
  const height = (width * 31) / 142;

  return (
    <svg width={width} height={height} viewBox="0 0 142 31" fill="none">
      <path d="M0 0L8.43524 24.1617L13.5904 24.1618L5.1551 2.82461e-06L0 0Z" fill="#1DF5E9" />
      <path d="M14.6996 2.3862e-05L20.6632 18.3826H20.8922L26.7131 2.3862e-05H32.6508L24.1451 24.1951H17.4224L8.90456 2.3862e-05H14.6996Z" fill="#AA71FC" />
      <path
        d="M38.747 24.5377C37.5664 24.5377 36.5142 24.3369 35.5905 23.9352C34.6668 23.5257 33.9359 22.9232 33.3978 22.1277C32.8677 21.3243 32.6026 20.3241 32.6026 19.1269C32.6026 18.1188 32.7914 17.2721 33.1689 16.5869C33.5464 15.9017 34.0604 15.3504 34.711 14.9329C35.3616 14.5155 36.1005 14.2005 36.9278 13.9878C37.7631 13.7752 38.6386 13.6255 39.5543 13.5389C40.6305 13.4286 41.498 13.3262 42.1566 13.2317C42.8152 13.1293 43.2931 12.9797 43.5903 12.7828C43.8875 12.5859 44.036 12.2945 44.036 11.9086V11.8377C44.036 11.0894 43.7951 10.5106 43.3132 10.101C42.8393 9.69145 42.1646 9.48668 41.2891 9.48668C40.3655 9.48668 39.6306 9.68752 39.0844 10.0892C38.5382 10.483 38.1768 10.9792 38.0001 11.5778L33.2532 11.1997C33.4942 10.0971 33.9681 9.14407 34.6749 8.34072C35.3817 7.52949 36.2933 6.90728 37.4097 6.4741C38.5342 6.03305 39.8354 5.81252 41.3132 5.81252C42.3413 5.81252 43.3252 5.93066 44.265 6.16694C45.2127 6.40322 46.052 6.76945 46.783 7.26564C47.5219 7.76183 48.1042 8.39979 48.5299 9.17951C48.9556 9.95136 49.1684 10.8768 49.1684 11.9558V24.1951H44.3011V21.6787H44.1565C43.8593 22.2458 43.4618 22.7459 42.9638 23.1791C42.4658 23.6044 41.8674 23.9392 41.1687 24.1833C40.4699 24.4196 39.6627 24.5377 38.747 24.5377ZM40.2169 21.0644C40.9719 21.0644 41.6385 20.9187 42.2168 20.6273C42.7951 20.328 43.2489 19.9263 43.5782 19.4223C43.9075 18.9182 44.0722 18.3472 44.0722 17.7092V15.7835C43.9115 15.8859 43.6907 15.9804 43.4096 16.0671C43.1365 16.1458 42.8272 16.2207 42.4819 16.2916C42.1365 16.3546 41.7911 16.4136 41.4458 16.4688C41.1004 16.516 40.7871 16.5593 40.506 16.5987C39.9036 16.6854 39.3775 16.8232 38.9278 17.0122C38.478 17.2012 38.1286 17.4572 37.8796 17.7801C37.6306 18.0952 37.5061 18.489 37.5061 18.9615C37.5061 19.6467 37.7591 20.1705 38.2651 20.5328C38.7792 20.8872 39.4298 21.0644 40.2169 21.0644Z"
        fill="#AA71FC"
      />
      <path
        d="M61.5114 24.5495C59.6159 24.5495 57.9854 24.1557 56.62 23.3681C55.2626 22.5727 54.2185 21.47 53.4876 20.0602C52.7647 18.6504 52.4033 17.028 52.4033 15.1928C52.4033 13.3341 52.7687 11.7038 53.4996 10.3018C54.2385 8.89204 55.2867 7.79333 56.6441 7.00573C58.0015 6.21026 59.6159 5.81252 61.4873 5.81252C63.1017 5.81252 64.5154 6.09999 65.7282 6.67494C66.941 7.24989 67.9008 8.05718 68.6076 9.09681C69.3144 10.1364 69.7039 11.3572 69.7762 12.7592H64.933C64.7965 11.8534 64.435 11.1249 63.8487 10.5736C63.2704 10.0144 62.5114 9.73477 61.5717 9.73477C60.7765 9.73477 60.0818 9.94742 59.4874 10.3727C58.9011 10.7902 58.4432 11.4005 58.1139 12.2039C57.7846 13.0073 57.62 13.9799 57.62 15.122C57.62 16.2797 57.7806 17.2642 58.1019 18.0755C58.4312 18.8867 58.893 19.505 59.4874 19.9303C60.0818 20.3556 60.7765 20.5682 61.5717 20.5682C62.158 20.5682 62.6841 20.4501 63.1499 20.2138C63.6238 19.9775 64.0134 19.6349 64.3186 19.186C64.6318 18.7292 64.8366 18.1818 64.933 17.5438H69.7762C69.6959 18.93 69.3104 20.1508 68.6196 21.2062C67.9369 22.2537 66.9932 23.0728 65.7884 23.6635C64.5836 24.2542 63.158 24.5495 61.5114 24.5495Z"
        fill="#AA71FC"
      />
      <path
        d="M78.3392 24.5377C77.1585 24.5377 76.1064 24.3369 75.1827 23.9352C74.259 23.5257 73.5281 22.9232 72.99 22.1277C72.4599 21.3243 72.1948 20.3241 72.1948 19.1269C72.1948 18.1188 72.3836 17.2721 72.7611 16.5869C73.1386 15.9017 73.6526 15.3504 74.3032 14.9329C74.9538 14.5155 75.6927 14.2005 76.52 13.9878C77.3553 13.7752 78.2308 13.6255 79.1464 13.5389C80.2227 13.4286 81.0902 13.3262 81.7488 13.2317C82.4074 13.1293 82.8853 12.9797 83.1825 12.7828C83.4796 12.5859 83.6282 12.2945 83.6282 11.9086V11.8377C83.6282 11.0894 83.3873 10.5106 82.9054 10.101C82.4315 9.69145 81.7568 9.48668 80.8813 9.48668C79.9577 9.48668 79.2227 9.68752 78.6766 10.0892C78.1304 10.483 77.769 10.9792 77.5923 11.5778L72.8454 11.1997C73.0864 10.0971 73.5603 9.14407 74.2671 8.34072C74.9739 7.52949 75.8855 6.90728 77.0019 6.4741C78.1264 6.03305 79.4276 5.81252 80.9054 5.81252C81.9335 5.81252 82.9174 5.93066 83.8571 6.16694C84.8049 6.40322 85.6442 6.76945 86.3751 7.26564C87.1141 7.76183 87.6964 8.39979 88.1221 9.17951C88.5478 9.95136 88.7606 10.8768 88.7606 11.9558V24.1951H83.8933V21.6787H83.7487C83.4515 22.2458 83.0539 22.7459 82.556 23.1791C82.058 23.6044 81.4596 23.9392 80.7608 24.1833C80.0621 24.4196 79.2549 24.5377 78.3392 24.5377ZM79.8091 21.0644C80.5641 21.0644 81.2307 20.9187 81.809 20.6273C82.3873 20.328 82.8411 19.9263 83.1704 19.4223C83.4997 18.9182 83.6644 18.3472 83.6644 17.7092V15.7835C83.5037 15.8859 83.2829 15.9804 83.0017 16.0671C82.7287 16.1458 82.4194 16.2207 82.0741 16.2916C81.7287 16.3546 81.3833 16.4136 81.0379 16.4688C80.6926 16.516 80.3793 16.5593 80.0982 16.5987C79.4958 16.6854 78.9697 16.8232 78.52 17.0122C78.0702 17.2012 77.7208 17.4572 77.4718 17.7801C77.2228 18.0952 77.0983 18.489 77.0983 18.9615C77.0983 19.6467 77.3513 20.1705 77.8573 20.5328C78.3714 20.8872 79.0219 21.0644 79.8091 21.0644Z"
        fill="#AA71FC"
      />
      <path
        d="M102.585 6.0488V9.82928H91.4413V6.0488H102.585ZM93.9713 4.10296L99.1037 1.70124V18.6189C99.1037 19.0836 99.176 19.4459 99.3205 19.7058C99.4651 19.9578 99.6659 20.135 99.9229 20.2374C100.188 20.3398 100.493 20.391 100.839 20.391C101.08 20.391 101.32 20.3713 101.561 20.3319C101.802 20.2847 101.987 20.2492 102.116 20.2256L102.923 23.9707C102.666 24.0494 102.304 24.14 101.839 24.2424C101.373 24.3526 100.806 24.4196 100.14 24.4432C98.9029 24.4905 97.8186 24.329 96.8869 23.9588C95.9632 23.5887 95.2443 23.0137 94.7303 22.234C94.2163 21.4543 93.9633 20.4698 93.9713 19.2805V4.10296Z"
        fill="#AA71FC"
      />
      <path
        d="M121.094 11.2233L116.395 11.5069C116.315 11.1131 116.142 10.7587 115.877 10.4436C115.612 10.1207 115.263 9.86473 114.829 9.6757C114.403 9.4788 113.893 9.38035 113.299 9.38035C112.504 9.38035 111.833 9.54575 111.287 9.87654C110.741 10.1995 110.468 10.6326 110.468 11.1761C110.468 11.6093 110.644 11.9755 110.998 12.2748C111.351 12.5741 111.958 12.8143 112.817 12.9954L116.166 13.657C117.966 14.0193 119.307 14.6021 120.19 15.4055C121.074 16.2089 121.516 17.2642 121.516 18.5717C121.516 19.7609 121.158 20.8045 120.443 21.7024C119.737 22.6002 118.765 23.3012 117.528 23.8053C116.299 24.3015 114.881 24.5495 113.275 24.5495C110.825 24.5495 108.873 24.0494 107.42 23.0492C105.974 22.041 105.127 20.6706 104.878 18.9379L109.926 18.678C110.078 19.4104 110.448 19.9696 111.034 20.3556C111.62 20.7336 112.371 20.9226 113.287 20.9226C114.187 20.9226 114.909 20.7533 115.456 20.4146C116.01 20.0681 116.291 19.6231 116.299 19.0797C116.291 18.6229 116.094 18.2487 115.709 17.9573C115.323 17.658 114.729 17.4296 113.926 17.2721L110.721 16.646C108.914 16.2916 107.568 15.6772 106.685 14.803C105.809 13.9287 105.372 12.8143 105.372 11.4596C105.372 10.294 105.693 9.28978 106.335 8.44704C106.986 7.60431 107.898 6.95454 109.07 6.49773C110.251 6.04092 111.632 5.81252 113.215 5.81252C115.552 5.81252 117.391 6.29689 118.733 7.26564C120.082 8.23439 120.869 9.55362 121.094 11.2233Z"
        fill="#AA71FC"
      />
      <path
        d="M127.916 31C127.265 31 126.655 30.9488 126.085 30.8464C125.523 30.7519 125.057 30.6298 124.687 30.4802L125.844 26.7233C126.446 26.9045 126.988 27.0029 127.47 27.0187C127.96 27.0344 128.382 26.9242 128.735 26.6879C129.097 26.4516 129.39 26.0499 129.615 25.4829L129.916 24.7149L123.278 6.0488H128.675L132.506 19.375H132.699L136.566 6.0488H142L134.807 26.1563C134.462 27.1329 133.992 27.9835 133.398 28.7081C132.812 29.4405 132.069 30.0037 131.169 30.3975C130.269 30.7992 129.185 31 127.916 31Z"
        fill="#AA71FC"
      />
    </svg>
  );
};

export default Vacatsy;
