import React from 'react';
import { Routes, Route } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import Dashboard from './components/Dashboard';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={ <PrivateRoute> <Dashboard /> </PrivateRoute> } />
      <Route path="/calendar" element={ <PrivateRoute> <Dashboard /> </PrivateRoute> } />
      <Route path="/inbox" element={ <PrivateRoute> <Dashboard /> </PrivateRoute> } />
      <Route path="/listing" element={ <PrivateRoute> <Dashboard /> </PrivateRoute> } />
    </Routes>
  );
};

export default App;
