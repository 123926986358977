import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import VacatsyLogo from './svg/Vacatsy.js';
import AccountMenu from './AccountMenu.js';

const Dashboard = props => {
  const { user, getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    console.log('#GC 12');
    const getToken = async () => {
      const accessToken = await getAccessTokenSilently();
      window.accessToken = accessToken;
      console.log(accessToken);
    };

    getToken();
  }, [getAccessTokenSilently, user?.sub]);

  return (
    <div>
      <div className="border-b border-slate-300 py-5">
        <div className="container mx-auto flex items-center">
          <VacatsyLogo size={142} />
          <div className="ml-auto text-sm font-medium flex items-center">
            <Link to="/calendar">Calendar</Link>
            <Link className="ml-6" to="/inbox">
              Inbox
            </Link>
            <Link className="ml-6" to="/listing">
              Listing
            </Link>

            <AccountMenu />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
